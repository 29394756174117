import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'

export const MapWrapper = styled(Img)`
  margin: auto;
  width: 100%;
  max-width: 900px;
  /* margin: 30px; */
  /* padding: 30px; */
  /* border-radius: 50%;
  height: 3.75rem;
  margin: auto;
  width: 3.75rem;

  ${media.lessThan('large')`
    height: 1.875rem;
    width: 1.875rem;
  `} */
`
