import styled from 'styled-components'
import media from 'styled-media-query'

export const Form = styled.form`
  width: 70%;
  min-width: 225px;

  textarea {
    min-height: 145px;
  }

  input:focus {
    border-color: #258e91 !important;
    box-shadow: none !important;
  }
  textarea:focus {
    border-color: #258e91 !important;
    box-shadow: none !important;
    min-height: 145px;
  }
`

export const ButtonSubmit = styled.button`
  border-color: #258e9133;
  -webkit-appearance: unset !important;
  border-radius: 0.25rem;
  background: ${props => props.theme.primary};
  box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
  color: white;
  display: inline-block;
  padding: .8125rem 1.25rem;
  transition-duration: 0.25s;
  height: 35px;
  display: flex;
  padding: 0 2rem;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1);
  }

  ${media.lessThan('small')`
    max-width: 100%;
  `}
`