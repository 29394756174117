import React from 'react'


import Link from '../Link'

import * as S from './styled'

export default ({text, type, Icon, href}) => (
  <S.Col xs={3} lg={3} className="d-flex flex-column align-items-center" style={{wordBreak: 'break-word'}}>
    <S.Cirle className="h-4-rem w-4-rem my-3 absolute-center rounded-circle">
      <Icon size={30}/>
    </S.Cirle>
    <div className="text-center">
      <h6>{type}</h6>
      <span className="text-secondary">
        {href ? <Link to={href} className="reset">{text}</Link> : text}
      </span>
    </div>
  </S.Col>
)
