import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import * as S from './styled'

const Map = () => {
  const { mapImage } = useStaticQuery(
    graphql`
      query {
        mapImage: file(relativePath: { eq: "map.png" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )

  return (
    <S.MapWrapper fluid={mapImage.childImageSharp.fluid} />
  )
}

export default Map
