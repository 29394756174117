import styled from 'styled-components'
import media from 'styled-media-query'
import { Col as bCol } from 'reactstrap'

export const Cirle = styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757d;
  background-color: #33a09626;
`

export const Col = styled(bCol)`
  ${media.lessThan('small')`
    flex: 0 0 100% !important;
    max-width: 100% !important;
  `}
`