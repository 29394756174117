import React from "react";
import Slider from "react-slick";

import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

import * as S from './styled'

export default ({ images }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: dots => (
      <div
        style={{
          // backgroundColor: "#ddd",
          position: "relative",
          bottom: "35px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  // let {title, author} = sideMetadata.site.siteMetadata
  return (
    <S.SliderContainer>
      <Slider {...settings}>
        <S.SlideWrapper fluid={images.childImageSharp.fluid} />
      </Slider>
    </S.SliderContainer>
  )
}
