import React from 'react'
import { NormalField } from 'reactstrap-form-fields'

import * as S from './styled'

export default () => (
  // <form name="FormContact" method="POST" netlify>
  <S.Form action="https://getform.io/f/5c5736ff-38f7-43c8-b850-89d8579cf8e9" method="POST">
    <NormalField name="Nome"/>
    <NormalField name="Email"/>
    <NormalField name="Telefone"/>
    <NormalField name="Digite sua mensagem" type="textarea"/>
    <S.ButtonSubmit type="submit">Enviar</S.ButtonSubmit>
  </S.Form>
)
