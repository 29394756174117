import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'

export const SliderContainer = styled.div`
  /* background-color: royalblue; */
  /* background: linear-gradient(210deg,#943cff 0%,#dd45d3 40.13%,#fc9a57 90%);
  color: white;
  padding: 10rem 0;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%); */
`

export const SlideWrapper = styled(Img)`
  height: calc(100vh - 200px);
  width: auto;

  ${media.lessThan('medium')`
    width: 100%;
    height: auto;
  `}
`