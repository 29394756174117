import styled from 'styled-components'
import media from 'styled-media-query'
import { Container as bContainer } from 'reactstrap'

export const StyledBackground = styled.div`
  background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
`
export const ContainerText = styled.div`
/* background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%); */

  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: 20px 0 60px 0;

  h2 {
    max-width: 60%;
    min-width: 300px;
    ${media.lessThan('small')`
      font-size: 25px;
    `}
  }

  p {
    margin-top: 15px;
    max-width: 50%;
    min-width: 300px;
    font-size: 20px;
  }

  a {
    color: #6c757d !important;
  }
`

export const ContactDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  ${media.lessThan('small')`
    flex-direction: column;
    align-items: center;
  `}
`

export const Cirle = styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContainerMap = styled(bContainer)`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding-bottom: 50px;
  background-color: #409c8c;
  margin-top: 50px;
  max-width: 100% !important;
  width: 100% !important;

  h4 {
    color: white;
    margin: 40px 0 30px 0;
  }
`