import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Row, Col } from 'reactstrap'
import SEO from "../components/seo"

import Layout from "../components/Layout"
import Banner from '../components/Banner'
import Box from '../components/Box'
import ContactItem from '../components/ContactItem'
import Hr from '../components/Hr'
import Form from '../components/Form'
import Map from '../components/Map'

import * as S from '../styles/fale-conosco'

import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'

export default () => {
  const { banner, site: { siteMetadata : about }  } = useStaticQuery(
    graphql`
      query {
        banner: file(relativePath: {eq: "banner-contact.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        site {
          siteMetadata {
            address
            email
            phone
          }
        }
      }
    `
  )
  about.phoneLink = about.phone.replace(/\D/gim, '');

  return (
    <Layout>
      <SEO title="Fale Conosco" description="Entre em contato conosco"/>
      <Banner images={banner}/>
      <Container className="pt-4">
        <S.ContainerText>
          <h4>Fale conosco</h4>
          <S.ContactDiv>
            <ContactItem text={about.address} type="Endereço" Icon={FaMapMarkerAlt}/>
            <ContactItem text={about.email} href={`mailto:${about.email}`} type="Email" Icon={FaEnvelope}/>
            <ContactItem text={about.phone} href={`tel:${about.phoneLink}`} type="Telefone" Icon={FaPhone}/>
          </S.ContactDiv>
        </S.ContainerText>
      </Container>
      <Container>
        <Row className="d-flex justify-content-center">
          <Col md={8}>
            <Box style={{textAlign: 'left'}}>
              <h3 className="text-center">Aguardamos seu contato</h3>
              <Hr/>
              <Form/>
            </Box>
          </Col>
        </Row>
      </Container>
      <S.ContainerMap className="pt-4">
        <h4>Onde atendemos</h4>
        <Map />
      </S.ContainerMap>
    </Layout>
  )
}
